import React, { useState, useEffect } from 'react';
import { MapPin, ChevronDown, Search } from 'lucide-react';
import type { Location } from '../types';
import { useStore } from '../store/useStore';

const WORLD_CITIES: Location[] = [
  // Indian Locations
  {
    id: 'delhi',
    name: 'New Delhi',
    country: 'India',
    latitude: 28.6139,
    longitude: 77.2090,
    climate: 'Monsoon',
    avgRainfall: 797,
    avgTemperature: 25.1,
  },
  {
    id: 'mumbai',
    name: 'Mumbai',
    country: 'India',
    latitude: 19.0760,
    longitude: 72.8777,
    climate: 'Tropical',
    avgRainfall: 2386,
    avgTemperature: 27.2,
  },
  {
    id: 'bangalore',
    name: 'Bangalore',
    country: 'India',
    latitude: 12.9716,
    longitude: 77.5946,
    climate: 'Subtropical Highland',
    avgRainfall: 970,
    avgTemperature: 23.6,
  },
  {
    id: 'chennai',
    name: 'Chennai',
    country: 'India',
    latitude: 13.0827,
    longitude: 80.2707,
    climate: 'Tropical Wet',
    avgRainfall: 1400,
    avgTemperature: 28.6,
  },
  {
    id: 'kolkata',
    name: 'Kolkata',
    country: 'India',
    latitude: 22.5726,
    longitude: 88.3639,
    climate: 'Tropical Wet',
    avgRainfall: 1582,
    avgTemperature: 26.8,
  },
  {
    id: 'hyderabad',
    name: 'Hyderabad',
    country: 'India',
    latitude: 17.3850,
    longitude: 78.4867,
    climate: 'Tropical Savanna',
    avgRainfall: 812,
    avgTemperature: 26.7,
  },
  {
    id: 'pune',
    name: 'Pune',
    country: 'India',
    latitude: 18.5204,
    longitude: 73.8567,
    climate: 'Tropical',
    avgRainfall: 722,
    avgTemperature: 24.9,
  },
  {
    id: 'ahmedabad',
    name: 'Ahmedabad',
    country: 'India',
    latitude: 23.0225,
    longitude: 72.5714,
    climate: 'Semi-arid',
    avgRainfall: 782,
    avgTemperature: 27.5,
  },
  {
    id: 'lucknow',
    name: 'Lucknow',
    country: 'India',
    latitude: 26.8467,
    longitude: 80.9462,
    climate: 'Humid Subtropical',
    avgRainfall: 896,
    avgTemperature: 25.8,
  },
  {
    id: 'jaipur',
    name: 'Jaipur',
    country: 'India',
    latitude: 26.9124,
    longitude: 75.7873,
    climate: 'Semi-arid',
    avgRainfall: 650,
    avgTemperature: 25.9,
  },
  {
    id: 'chandigarh',
    name: 'Chandigarh',
    country: 'India',
    latitude: 30.7333,
    longitude: 76.7794,
    climate: 'Humid Subtropical',
    avgRainfall: 1110,
    avgTemperature: 23.3,
  },
  {
    id: 'bhopal',
    name: 'Bhopal',
    country: 'India',
    latitude: 23.2599,
    longitude: 77.4126,
    climate: 'Subtropical',
    avgRainfall: 1146,
    avgTemperature: 25.1,
  },
  {
    id: 'patna',
    name: 'Patna',
    country: 'India',
    latitude: 25.5941,
    longitude: 85.1376,
    climate: 'Humid Subtropical',
    avgRainfall: 1100,
    avgTemperature: 26.0,
  },
  {
    id: 'kochi',
    name: 'Kochi',
    country: 'India',
    latitude: 9.9312,
    longitude: 76.2673,
    climate: 'Tropical Monsoon',
    avgRainfall: 3005,
    avgTemperature: 27.5,
  },
  {
    id: 'guwahati',
    name: 'Guwahati',
    country: 'India',
    latitude: 26.1445,
    longitude: 91.7362,
    climate: 'Subtropical Highland',
    avgRainfall: 1722,
    avgTemperature: 24.1,
  },
  // International Cities
  {
    id: 'amsterdam',
    name: 'Amsterdam',
    country: 'Netherlands',
    latitude: 52.3676,
    longitude: 4.9041,
    climate: 'Oceanic',
    avgRainfall: 838,
    avgTemperature: 10.2,
  },
  {
    id: 'bangkok',
    name: 'Bangkok',
    country: 'Thailand',
    latitude: 13.7563,
    longitude: 100.5018,
    climate: 'Tropical',
    avgRainfall: 1648,
    avgTemperature: 28.2,
  },
  {
    id: 'london',
    name: 'London',
    country: 'United Kingdom',
    latitude: 51.5074,
    longitude: -0.1278,
    climate: 'Temperate',
    avgRainfall: 583,
    avgTemperature: 11.3,
  },
  {
    id: 'newyork',
    name: 'New York',
    country: 'United States',
    latitude: 40.7128,
    longitude: -74.0060,
    climate: 'Humid Subtropical',
    avgRainfall: 1174,
    avgTemperature: 12.9,
  },
  {
    id: 'tokyo',
    name: 'Tokyo',
    country: 'Japan',
    latitude: 35.6762,
    longitude: 139.6503,
    climate: 'Humid Subtropical',
    avgRainfall: 1530,
    avgTemperature: 15.4,
  }
];

export const LocationSelector = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { selectedLocation, setSelectedLocation } = useStore();

  useEffect(() => {
    if (!selectedLocation) {
      setSelectedLocation(WORLD_CITIES.find(city => city.id === 'delhi') || WORLD_CITIES[0]);
    }
  }, [selectedLocation, setSelectedLocation]);

  const filteredCities = WORLD_CITIES.filter(
    city =>
      city.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      city.country.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const groupedCities = filteredCities.reduce((acc, city) => {
    const country = city.country;
    if (!acc[country]) {
      acc[country] = [];
    }
    acc[country].push(city);
    return acc;
  }, {} as Record<string, Location[]>);

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between px-4 py-2 bg-white dark:bg-gray-800 border dark:border-gray-700 rounded-lg shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
      >
        <div className="flex items-center space-x-2">
          <MapPin className="h-5 w-5 text-primary-500" />
          <span className="text-gray-700 dark:text-gray-300">
            {selectedLocation ? (
              <span>
                {selectedLocation.name}, <span className="text-gray-500 dark:text-gray-400">{selectedLocation.country}</span>
              </span>
            ) : (
              'Select Location'
            )}
          </span>
        </div>
        <ChevronDown className="h-5 w-5 text-gray-400" />
      </button>

      {isOpen && (
        <div className="absolute z-50 w-full mt-2 bg-white dark:bg-gray-800 border dark:border-gray-700 rounded-lg shadow-lg max-h-96 overflow-hidden">
          <div className="p-2 border-b dark:border-gray-700">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
              <input
                type="text"
                placeholder="Search cities..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-9 pr-4 py-2 bg-gray-50 dark:bg-gray-700 border-0 rounded-md text-sm focus:ring-2 focus:ring-primary-500"
              />
            </div>
          </div>
          <div className="overflow-y-auto max-h-80">
            {Object.entries(groupedCities).map(([country, cities]) => (
              <div key={country}>
                <div className="px-4 py-2 bg-gray-50 dark:bg-gray-700/50 text-sm font-medium text-gray-500 dark:text-gray-400">
                  {country}
                </div>
                {cities.map((location) => (
                  <button
                    key={location.id}
                    onClick={() => {
                      setSelectedLocation(location);
                      setIsOpen(false);
                      setSearchQuery('');
                    }}
                    className="w-full px-4 py-3 text-left hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors flex justify-between items-center"
                  >
                    <div>
                      <h3 className="font-medium text-gray-900 dark:text-white">
                        {location.name}
                      </h3>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {location.climate}
                      </p>
                    </div>
                    <div className="text-right text-sm">
                      <p className="text-gray-600 dark:text-gray-300">{location.avgTemperature}°C</p>
                      <p className="text-gray-500 dark:text-gray-400">{location.avgRainfall}mm/year</p>
                    </div>
                  </button>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};