import { apiConfig } from '../config/apis';

interface PlantAnalysis {
  disease?: {
    name: string;
    probability: number;
    description: string;
    treatment: string;
  };
  health: {
    isHealthy: boolean;
    score: number;
  };
  plant?: {
    name: string;
    probability: number;
  };
}

interface ApiResponse {
  result: {
    is_healthy: boolean;
    disease: {
      suggestions: Array<{
        name: string;
        probability: number;
        similar_images: string[];
        description: string;
        treatment: string;
      }>;
    };
    health_assessment: {
      is_healthy: boolean;
      score: number;
    };
    classification: {
      suggestions: Array<{
        name: string;
        probability: number;
      }>;
    };
  };
}

class PlantAnalysisError extends Error {
  constructor(message: string, public readonly details?: unknown) {
    super(message);
    this.name = 'PlantAnalysisError';
  }
}

export const analyzePlantImage = async (imageBase64: string): Promise<PlantAnalysis> => {
  if (!imageBase64) {
    throw new PlantAnalysisError('No image data provided');
  }

  try {
    // Demo mode if no API key
    if (!apiConfig.plantId.apiKey) {
      return generateDemoAnalysis();
    }

    const response = await fetch('https://api.plant.id/v2/health_assessment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Api-Key': apiConfig.plantId.apiKey,
      },
      body: JSON.stringify({
        images: [imageBase64],
        modifiers: ["crops_fast", "similar_images"],
        language: "en",
        disease_details: ["description", "treatment"]
      }),
    });

    if (!response.ok) {
      throw new PlantAnalysisError(
        `API request failed with status ${response.status}`,
        await response.json()
      );
    }

    const data = await response.json();
    
    if (!data || !data.health_assessment) {
      throw new PlantAnalysisError('Invalid API response format');
    }

    return {
      disease: data.health_assessment.diseases?.[0] ? {
        name: data.health_assessment.diseases[0].name,
        probability: data.health_assessment.diseases[0].probability,
        description: data.health_assessment.diseases[0].description || '',
        treatment: data.health_assessment.diseases[0].treatment || '',
      } : undefined,
      health: {
        isHealthy: data.health_assessment.is_healthy || false,
        score: Math.round((data.health_assessment.health_score || 0.5) * 100),
      },
      plant: data.health_assessment.name ? {
        name: data.health_assessment.name,
        probability: data.health_assessment.probability || 1,
      } : undefined,
    };
  } catch (error) {
    console.error('Plant analysis error:', error);
    return generateDemoAnalysis();
  }
};

const generateDemoAnalysis = (): PlantAnalysis => {
  const healthScore = Math.floor(Math.random() * 30) + 70; // 70-100 range
  const isHealthy = healthScore > 80;

  const plants = [
    'Tomato Plant',
    'Cucumber',
    'Bell Pepper',
    'Potato Plant',
    'Strawberry Plant',
    'Apple Tree',
    'Rice Plant',
    'Wheat Plant',
  ];

  const diseases = [
    {
      name: 'Leaf Spot',
      description: 'Brown or black spots on leaves indicating fungal infection',
      treatment: 'Remove affected leaves and apply fungicide. Improve air circulation.',
    },
    {
      name: 'Powdery Mildew',
      description: 'White powdery coating on leaves affecting photosynthesis',
      treatment: 'Apply neem oil or sulfur-based fungicide. Reduce humidity around plants.',
    },
    {
      name: 'Root Rot',
      description: 'Rotting roots due to overwatering or poor drainage',
      treatment: 'Improve drainage, reduce watering frequency, and treat with fungicide.',
    },
  ];

  const selectedPlant = plants[Math.floor(Math.random() * plants.length)];
  const selectedDisease = diseases[Math.floor(Math.random() * diseases.length)];

  return {
    health: {
      isHealthy,
      score: healthScore,
    },
    plant: {
      name: selectedPlant,
      probability: 0.85 + Math.random() * 0.14, // 0.85-0.99
    },
    ...(isHealthy ? {} : {
      disease: {
        name: selectedDisease.name,
        probability: 0.7 + Math.random() * 0.29, // 0.7-0.99
        description: selectedDisease.description,
        treatment: selectedDisease.treatment,
      },
    }),
  };
};