import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { CropData } from '../types/crops';

export const importCropData = async (data: CropData[]): Promise<void> => {
  if (!db) return;

  try {
    const batch = data.map(crop => 
      addDoc(collection(db, 'crops'), {
        ...crop,
        cropYear: Number(crop.cropYear),
        area: Number(crop.area),
        production: Number(crop.production),
        annualRainfall: Number(crop.annualRainfall),
        fertilizer: Number(crop.fertilizer),
        pesticide: Number(crop.pesticide),
        yield: Number(crop.yield)
      })
    );

    await Promise.all(batch);
  } catch (error) {
    console.error('Error importing crop data:', error);
    throw error;
  }
};

export const getCropData = async (
  state?: string,
  crop?: string,
  year?: number
): Promise<CropData[]> => {
  if (!db) return [];

  try {
    let q = collection(db, 'crops');
    
    if (state) {
      q = query(q, where('state', '==', state));
    }
    if (crop) {
      q = query(q, where('crop', '==', crop));
    }
    if (year) {
      q = query(q, where('cropYear', '==', year));
    }

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    } as CropData));
  } catch (error) {
    console.error('Error fetching crop data:', error);
    return [];
  }
};