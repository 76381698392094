import React from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import type { Feature, Geometry } from 'geojson';

interface Props {
  data: Record<string, {
    production: number;
    area: number;
    yield: number;
  }>;
}

export const CropYieldMap: React.FC<Props> = ({ data }) => {
  // This would be replaced with actual GeoJSON data for Indian states
  const indiaGeoJson: Feature<Geometry, any>[] = [];

  const getColorByYieldValue = (yieldValue: number) => {
    if (yieldValue > 2) return '#10B981';
    if (yieldValue > 1.5) return '#34D399';
    if (yieldValue > 1) return '#6EE7B7';
    return '#A7F3D0';
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow">
      <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
        State-wise Yield Distribution
      </h3>
      <div className="h-80 rounded-lg overflow-hidden">
        <MapContainer
          center={[20.5937, 78.9629]}
          zoom={4}
          style={{ height: '100%', width: '100%' }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {indiaGeoJson.map((feature) => (
            <GeoJSON
              key={feature.properties.state}
              data={feature}
              style={(feature) => ({
                fillColor: getColorByYieldValue(data[feature?.properties?.state]?.yield || 0),
                weight: 1,
                opacity: 1,
                color: 'white',
                fillOpacity: 0.7
              })}
            />
          ))}
        </MapContainer>
      </div>
    </div>
  );
};