import React, { useState, useEffect } from 'react';
import { BarChart3, Filter, Leaf, Loader2, MapPin } from 'lucide-react';
import { getCropData } from '../services/cropDatabase';
import type { CropData, CropAnalytics } from '../types/crops';
import { CropProductionChart } from './CropProductionChart';
import { CropYieldMap } from './CropYieldMap';
import { CropSeasonalAnalysis } from './CropSeasonalAnalysis';

export const IndianCrops: React.FC = () => {
  const [cropData, setCropData] = useState<CropData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedState, setSelectedState] = useState<string>('');
  const [selectedCrop, setSelectedCrop] = useState<string>('');
  const [analytics, setAnalytics] = useState<CropAnalytics | null>(null);

  const states = ['Assam', 'Karnataka', 'Kerala', 'Meghalaya', 'West Bengal'];
  const crops = ['Rice', 'Wheat', 'Maize', 'Potato', 'Sugarcane'];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getCropData(selectedState, selectedCrop);
        setCropData(data);
        
        // Calculate analytics
        const analytics: CropAnalytics = {
          totalProduction: data.reduce((sum, item) => sum + item.production, 0),
          averageYield: data.reduce((sum, item) => sum + item.yield, 0) / data.length,
          stateWiseData: {},
          seasonalData: {}
        };

        // Process state-wise data
        data.forEach(item => {
          if (!analytics.stateWiseData[item.state]) {
            analytics.stateWiseData[item.state] = {
              production: 0,
              area: 0,
              yield: 0
            };
          }
          analytics.stateWiseData[item.state].production += item.production;
          analytics.stateWiseData[item.state].area += item.area;
          analytics.stateWiseData[item.state].yield += item.yield;
        });

        // Process seasonal data
        data.forEach(item => {
          if (!analytics.seasonalData[item.season]) {
            analytics.seasonalData[item.season] = {
              production: 0,
              count: 0
            };
          }
          analytics.seasonalData[item.season].production += item.production;
          analytics.seasonalData[item.season].count += 1;
        });

        setAnalytics(analytics);
      } catch (err) {
        setError('Failed to fetch crop data');
        console.error('Error fetching crop data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedState, selectedCrop]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-primary-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 dark:bg-red-900/20 p-4 rounded-lg text-red-600 dark:text-red-400">
        {error}
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white flex items-center">
          <Leaf className="h-6 w-6 mr-2 text-green-600" />
          Indian Crops Analytics
        </h2>

        <div className="flex space-x-4">
          <div className="relative">
            <MapPin className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            <select
              value={selectedState}
              onChange={(e) => setSelectedState(e.target.value)}
              className="pl-10 pr-4 py-2 border dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
            >
              <option value="">All States</option>
              {states.map(state => (
                <option key={state} value={state}>{state}</option>
              ))}
            </select>
          </div>

          <div className="relative">
            <Filter className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            <select
              value={selectedCrop}
              onChange={(e) => setSelectedCrop(e.target.value)}
              className="pl-10 pr-4 py-2 border dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
            >
              <option value="">All Crops</option>
              {crops.map(crop => (
                <option key={crop} value={crop}>{crop}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {analytics && (
        <div className="space-y-6">
          {/* Overview Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-green-50 dark:bg-green-900/20 p-6 rounded-lg">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium text-green-700 dark:text-green-300">
                  Total Production
                </h3>
                <BarChart3 className="h-6 w-6 text-green-500" />
              </div>
              <p className="text-3xl font-bold text-green-700 dark:text-green-300 mt-2">
                {analytics.totalProduction.toLocaleString()} MT
              </p>
            </div>

            <div className="bg-blue-50 dark:bg-blue-900/20 p-6 rounded-lg">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium text-blue-700 dark:text-blue-300">
                  Average Yield
                </h3>
                <BarChart3 className="h-6 w-6 text-blue-500" />
              </div>
              <p className="text-3xl font-bold text-blue-700 dark:text-blue-300 mt-2">
                {analytics.averageYield.toFixed(2)} MT/Ha
              </p>
            </div>

            <div className="bg-purple-50 dark:bg-purple-900/20 p-6 rounded-lg">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium text-purple-700 dark:text-purple-300">
                  Total Varieties
                </h3>
                <Leaf className="h-6 w-6 text-purple-500" />
              </div>
              <p className="text-3xl font-bold text-purple-700 dark:text-purple-300 mt-2">
                {Object.keys(cropData.reduce((acc, item) => ({...acc, [item.crop]: true}), {})).length}
              </p>
            </div>
          </div>

          {/* Charts */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <CropProductionChart data={cropData} />
            <CropYieldMap data={analytics.stateWiseData} />
          </div>

          {/* Seasonal Analysis */}
          <CropSeasonalAnalysis data={analytics.seasonalData} />
        </div>
      )}
    </div>
  );
};