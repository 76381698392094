import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import type { CropData } from '../types/crops';

interface Props {
  data: CropData[];
}

export const CropProductionChart: React.FC<Props> = ({ data }) => {
  const chartData = data.reduce((acc, item) => {
    if (!acc[item.crop]) {
      acc[item.crop] = {
        name: item.crop,
        production: 0,
        area: 0
      };
    }
    acc[item.crop].production += item.production;
    acc[item.crop].area += item.area;
    return acc;
  }, {} as Record<string, { name: string; production: number; area: number; }>);

  const formattedData = Object.values(chartData)
    .sort((a, b) => b.production - a.production)
    .slice(0, 10);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow">
      <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
        Top 10 Crops by Production
      </h3>
      <div className="h-80">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={formattedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" angle={-45} textAnchor="end" height={70} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="production" name="Production (MT)" fill="#10B981" />
            <Bar dataKey="area" name="Area (Ha)" fill="#3B82F6" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};