import { Crop } from '../types';

export const CROPS_BY_CLIMATE: Record<string, Crop[]> = {
  'Tropical': [
    { id: 'rice', name: 'Rice', phRange: { min: 5.5, max: 6.5 }, tempRange: { min: 22, max: 32 }, rainfall: { min: 1000, max: 2500 }, season: ['Summer', 'Monsoon'], growthDuration: 120 },
    { id: 'sugarcane', name: 'Sugarcane', phRange: { min: 6.0, max: 7.5 }, tempRange: { min: 20, max: 35 }, rainfall: { min: 1500, max: 2500 }, season: ['Spring', 'Summer'], growthDuration: 365 },
    { id: 'banana', name: 'Banana', phRange: { min: 5.5, max: 7.0 }, tempRange: { min: 20, max: 30 }, rainfall: { min: 1200, max: 2200 }, season: ['Spring', 'Summer'], growthDuration: 300 },
    { id: 'coconut', name: 'Coconut', phRange: { min: 5.5, max: 7.0 }, tempRange: { min: 20, max: 35 }, rainfall: { min: 1500, max: 2500 }, season: ['Spring', 'Summer'], growthDuration: 365 }
  ],
  'Monsoon': [
    { id: 'rice', name: 'Rice', phRange: { min: 5.5, max: 6.5 }, tempRange: { min: 22, max: 32 }, rainfall: { min: 1000, max: 2500 }, season: ['Summer', 'Monsoon'], growthDuration: 120 },
    { id: 'maize', name: 'Maize', phRange: { min: 5.5, max: 7.0 }, tempRange: { min: 20, max: 30 }, rainfall: { min: 600, max: 1200 }, season: ['Spring', 'Summer'], growthDuration: 95 },
    { id: 'soybean', name: 'Soybean', phRange: { min: 6.0, max: 7.0 }, tempRange: { min: 20, max: 30 }, rainfall: { min: 600, max: 1500 }, season: ['Summer', 'Monsoon'], growthDuration: 100 },
    { id: 'cotton', name: 'Cotton', phRange: { min: 5.5, max: 7.5 }, tempRange: { min: 20, max: 35 }, rainfall: { min: 600, max: 1500 }, season: ['Summer', 'Monsoon'], growthDuration: 150 }
  ],
  'Subtropical Highland': [
    { id: 'tea', name: 'Tea', phRange: { min: 4.5, max: 5.5 }, tempRange: { min: 18, max: 28 }, rainfall: { min: 1500, max: 2500 }, season: ['Spring', 'Summer'], growthDuration: 365 },
    { id: 'coffee', name: 'Coffee', phRange: { min: 5.0, max: 6.0 }, tempRange: { min: 15, max: 25 }, rainfall: { min: 1500, max: 2500 }, season: ['Spring', 'Summer'], growthDuration: 365 },
    { id: 'potato', name: 'Potato', phRange: { min: 5.0, max: 6.5 }, tempRange: { min: 15, max: 25 }, rainfall: { min: 500, max: 1000 }, season: ['Spring', 'Fall'], growthDuration: 100 }
  ],
  'Semi-arid': [
    { id: 'millet', name: 'Millet', phRange: { min: 5.5, max: 7.0 }, tempRange: { min: 20, max: 35 }, rainfall: { min: 400, max: 800 }, season: ['Summer'], growthDuration: 90 },
    { id: 'sorghum', name: 'Sorghum', phRange: { min: 5.5, max: 7.5 }, tempRange: { min: 20, max: 35 }, rainfall: { min: 400, max: 800 }, season: ['Summer'], growthDuration: 120 },
    { id: 'chickpea', name: 'Chickpea', phRange: { min: 6.0, max: 8.0 }, tempRange: { min: 15, max: 30 }, rainfall: { min: 400, max: 800 }, season: ['Winter'], growthDuration: 100 }
  ],
  'Humid Subtropical': [
    { id: 'rice', name: 'Rice', phRange: { min: 5.5, max: 6.5 }, tempRange: { min: 22, max: 32 }, rainfall: { min: 1000, max: 2500 }, season: ['Summer', 'Monsoon'], growthDuration: 120 },
    { id: 'wheat', name: 'Wheat', phRange: { min: 6.0, max: 7.0 }, tempRange: { min: 15, max: 25 }, rainfall: { min: 600, max: 1100 }, season: ['Winter'], growthDuration: 120 },
    { id: 'mustard', name: 'Mustard', phRange: { min: 6.0, max: 7.5 }, tempRange: { min: 15, max: 25 }, rainfall: { min: 400, max: 800 }, season: ['Winter'], growthDuration: 110 }
  ],
  'Temperate': [
    { id: 'wheat', name: 'Wheat', phRange: { min: 6.0, max: 7.0 }, tempRange: { min: 15, max: 25 }, rainfall: { min: 600, max: 1100 }, season: ['Winter'], growthDuration: 120 },
    { id: 'barley', name: 'Barley', phRange: { min: 6.0, max: 7.0 }, tempRange: { min: 15, max: 25 }, rainfall: { min: 500, max: 1000 }, season: ['Winter'], growthDuration: 90 },
    { id: 'oats', name: 'Oats', phRange: { min: 6.0, max: 7.0 }, tempRange: { min: 15, max: 25 }, rainfall: { min: 500, max: 1000 }, season: ['Winter'], growthDuration: 90 }
  ]
};