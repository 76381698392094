import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateProfile as firebaseUpdateProfile,
  updatePassword as firebaseUpdatePassword,
  updateEmail as firebaseUpdateEmail,
  User,
  AuthError
} from 'firebase/auth';
import { auth } from '../lib/firebase';

const getAuthErrorMessage = (error: AuthError): string => {
  switch (error.code) {
    case 'auth/email-already-in-use':
      return 'An account with this email already exists. Please sign in instead.';
    case 'auth/invalid-credential':
      return 'Invalid email or password. Please try again.';
    case 'auth/user-not-found':
      return 'No account found with this email address.';
    case 'auth/wrong-password':
      return 'Incorrect password. Please try again.';
    case 'auth/too-many-requests':
      return 'Too many failed attempts. Please try again later.';
    case 'auth/network-request-failed':
      return 'Network error. Please check your connection and try again.';
    case 'auth/requires-recent-login':
      return 'This operation is sensitive and requires recent authentication. Please log in again.';
    default:
      return 'An error occurred. Please try again.';
  }
};

export const signIn = async (email: string, password: string): Promise<User> => {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    if (!result.user.emailVerified) {
      await sendEmailVerification(result.user);
      await signOut();
      throw new Error('Please verify your email before signing in. A new verification link has been sent to your inbox.');
    }
    return result.user;
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    throw new Error(getAuthErrorMessage(error as AuthError));
  }
};

export const signUp = async (email: string, password: string): Promise<User> => {
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    await sendEmailVerification(result.user);
    await signOut();
    return result.user;
  } catch (error) {
    throw new Error(getAuthErrorMessage(error as AuthError));
  }
};

export const signOut = async (): Promise<void> => {
  try {
    await firebaseSignOut(auth);
  } catch (error) {
    console.error('Sign out error:', error);
    throw new Error('Failed to sign out. Please try again.');
  }
};

export const resetPassword = async (email: string): Promise<void> => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    throw new Error(getAuthErrorMessage(error as AuthError));
  }
};

export const updateProfile = async (user: User, profile: { displayName?: string | null; photoURL?: string | null }): Promise<void> => {
  try {
    await firebaseUpdateProfile(user, profile);
  } catch (error) {
    throw new Error(getAuthErrorMessage(error as AuthError));
  }
};

export const updatePassword = async (user: User, newPassword: string): Promise<void> => {
  try {
    await firebaseUpdatePassword(user, newPassword);
  } catch (error) {
    throw new Error(getAuthErrorMessage(error as AuthError));
  }
};

export const updateEmail = async (user: User, newEmail: string): Promise<void> => {
  try {
    await firebaseUpdateEmail(user, newEmail);
    await sendEmailVerification(user);
  } catch (error) {
    throw new Error(getAuthErrorMessage(error as AuthError));
  }
};