import React, { useState, useEffect } from 'react';
import { Sprout, ThermometerSun, Droplets, Calendar as CalendarIcon } from 'lucide-react';
import { useStore } from '../store/useStore';
import { CROPS_BY_CLIMATE } from '../data/crops';
import type { Season, Crop } from '../types';

export const CropSelection = () => {
  const { selectedLocation } = useStore();
  const [phValue, setPhValue] = useState(7);
  const [tempRange, setTempRange] = useState({ min: 15, max: 30 });
  const [rainfall, setRainfall] = useState(1000);
  const [season, setSeason] = useState<Season>('Spring');
  const [recommendedCrops, setRecommendedCrops] = useState<Crop[]>([]);

  // Update initial values when location changes
  useEffect(() => {
    if (selectedLocation) {
      const avgTemp = selectedLocation.avgTemperature;
      setTempRange({
        min: Math.round(avgTemp - 5),
        max: Math.round(avgTemp + 5)
      });
      setRainfall(selectedLocation.avgRainfall);
    }
  }, [selectedLocation]);

  // Update crop recommendations when any filter changes
  useEffect(() => {
    if (selectedLocation) {
      const climateCrops = CROPS_BY_CLIMATE[selectedLocation.climate] || [];
      const filtered = climateCrops.filter(crop => 
        crop.season.includes(season) &&
        crop.tempRange.min <= tempRange.max &&
        crop.tempRange.max >= tempRange.min &&
        crop.rainfall.min <= rainfall &&
        crop.rainfall.max >= rainfall &&
        crop.phRange.min <= phValue &&
        crop.phRange.max >= phValue
      );
      setRecommendedCrops(filtered);
    }
  }, [selectedLocation, season, tempRange.min, tempRange.max, rainfall, phValue]);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white flex items-center">
        <Sprout className="h-6 w-6 mr-2 text-green-600" />
        Crop Selection
      </h2>

      <div className="space-y-6">
        <div className="form-group">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Soil pH
          </label>
          <input
            type="range"
            min="0"
            max="14"
            step="0.1"
            value={phValue}
            onChange={(e) => setPhValue(parseFloat(e.target.value))}
            className="w-full"
          />
          <div className="flex justify-between text-sm text-gray-600 dark:text-gray-400">
            <span>Acidic (0)</span>
            <span>Neutral ({phValue.toFixed(1)})</span>
            <span>Alkaline (14)</span>
          </div>
        </div>

        <div className="form-group">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 flex items-center">
            <ThermometerSun className="h-4 w-4 mr-2" />
            Temperature Range (°C)
          </label>
          <div className="flex space-x-4">
            <input
              type="number"
              value={tempRange.min}
              onChange={(e) => setTempRange(prev => ({ ...prev, min: parseInt(e.target.value) || prev.min }))}
              className="flex-1 input-field"
            />
            <input
              type="number"
              value={tempRange.max}
              onChange={(e) => setTempRange(prev => ({ ...prev, max: parseInt(e.target.value) || prev.max }))}
              className="flex-1 input-field"
            />
          </div>
        </div>

        <div className="form-group">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 flex items-center">
            <Droplets className="h-4 w-4 mr-2" />
            Annual Rainfall (mm)
          </label>
          <input
            type="number"
            value={rainfall}
            onChange={(e) => setRainfall(parseInt(e.target.value) || rainfall)}
            className="input-field"
          />
        </div>

        <div className="form-group">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 flex items-center">
            <CalendarIcon className="h-4 w-4 mr-2" />
            Growing Season
          </label>
          <select 
            value={season}
            onChange={(e) => setSeason(e.target.value as Season)}
            className="input-field"
          >
            <option value="Spring">Spring</option>
            <option value="Summer">Summer</option>
            <option value="Fall">Fall</option>
            <option value="Winter">Winter</option>
          </select>
        </div>

        {recommendedCrops.length > 0 ? (
          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-4 text-gray-800 dark:text-white">
              Recommended Crops for {selectedLocation?.climate}
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {recommendedCrops.map((crop) => (
                <div
                  key={crop.id}
                  className="bg-green-50 dark:bg-green-900/20 p-4 rounded-lg"
                >
                  <h4 className="font-medium text-green-800 dark:text-green-200 mb-2">
                    {crop.name}
                  </h4>
                  <div className="space-y-1 text-sm text-green-700 dark:text-green-300">
                    <p>Growth Duration: {crop.growthDuration} days</p>
                    <p>Temperature: {crop.tempRange.min}°C - {crop.tempRange.max}°C</p>
                    <p>Rainfall: {crop.rainfall.min}-{crop.rainfall.max} mm</p>
                    <p>pH Range: {crop.phRange.min}-{crop.phRange.max}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="mt-6 bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-lg">
            <p className="text-yellow-700 dark:text-yellow-300">
              No crops match the current conditions. Try adjusting the parameters.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};