import React, { useState } from 'react';
import { Navigation } from './components/Navigation';
import { LocationSelector } from './components/LocationSelector';
import { WeatherDashboard } from './components/WeatherDashboard';
import { CropSelection } from './components/CropSelection';
import { PlantingCalendar } from './components/PlantingCalendar';
import { IrrigationManagement } from './components/IrrigationManagement';
import { KnowledgeBase } from './components/KnowledgeBase';
import { Analytics } from './components/Analytics';
import { FarmCalculator } from './components/FarmCalculator';
import { ServicesDirectory } from './components/ServicesDirectory';
import { Profile } from './components/Profile';
import { Auth } from './components/Auth';
import { IndianCrops } from './components/IndianCrops';
import { useAuth } from './hooks/useAuth';
import { 
  Cloud, 
  Sprout, 
  Calendar, 
  Droplets, 
  BookOpen,
  HelpCircle,
  BarChart3,
  Calculator,
  Store,
  User,
  Leaf
} from 'lucide-react';

function App() {
  const { user, loading } = useAuth();
  const [activeWidget, setActiveWidget] = useState('weather');

  const widgets = [
    { id: 'weather', icon: Cloud, component: WeatherDashboard, title: 'Weather' },
    { id: 'crops', icon: Sprout, component: CropSelection, title: 'Crops' },
    { id: 'indianCrops', icon: Leaf, component: IndianCrops, title: 'Indian Crops' },
    { id: 'calendar', icon: Calendar, component: PlantingCalendar, title: 'Calendar' },
    { id: 'irrigation', icon: Droplets, component: IrrigationManagement, title: 'Irrigation' },
    { id: 'analytics', icon: BarChart3, component: Analytics, title: 'Analytics' },
    { id: 'calculator', icon: Calculator, component: FarmCalculator, title: 'Calculator' },
    { id: 'services', icon: Store, component: ServicesDirectory, title: 'Services' },
    { id: 'knowledge', icon: BookOpen, component: KnowledgeBase, title: 'Knowledge Base' },
    { id: 'profile', icon: User, component: Profile, title: 'Profile' }
  ];

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  if (!user) {
    return <Auth />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 transition-colors duration-200">
      <Navigation />
      
      <div className="flex">
        {/* Sidebar */}
        <aside className="w-20 lg:w-64 h-[calc(100vh-4rem)] fixed left-0 bg-white dark:bg-gray-800 shadow-lg">
          <div className="p-4 lg:p-6">
            <LocationSelector />
            
            <div className="mt-8 space-y-2">
              {widgets.map(({ id, icon: Icon, title }) => (
                <button
                  key={id}
                  onClick={() => setActiveWidget(id)}
                  className={`w-full flex items-center p-3 rounded-lg transition-colors ${
                    activeWidget === id
                      ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                      : 'hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-600 dark:text-gray-400'
                  }`}
                >
                  <Icon className="h-6 w-6 lg:mr-3" />
                  <span className="hidden lg:inline">{title}</span>
                </button>
              ))}
            </div>
          </div>
        </aside>

        {/* Main Content */}
        <main className="ml-20 lg:ml-64 flex-1 p-6">
          <div className="max-w-6xl mx-auto">
            {widgets.map(({ id, component: Component }) => (
              <div
                key={id}
                className={`transition-all duration-300 ${
                  activeWidget === id ? 'opacity-100' : 'hidden opacity-0'
                }`}
              >
                <Component />
              </div>
            ))}
          </div>
        </main>
      </div>
    </div>
  );
}

export default App;