import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

interface Props {
  data: Record<string, {
    production: number;
    count: number;
  }>;
}

export const CropSeasonalAnalysis: React.FC<Props> = ({ data }) => {
  const COLORS = ['#10B981', '#3B82F6', '#8B5CF6', '#EC4899'];

  const chartData = Object.entries(data).map(([season, values]) => ({
    name: season,
    value: values.production
  }));

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow">
      <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
        Seasonal Production Distribution
      </h3>
      <div className="h-80">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={chartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={100}
              label
            >
              {chartData.map((entry, index) => (
                <Cell key={entry.name} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-6">
        {Object.entries(data).map(([season, values], index) => (
          <div
            key={season}
            className="p-4 rounded-lg"
            style={{ backgroundColor: `${COLORS[index % COLORS.length]}15` }}
          >
            <h4 className="font-medium text-gray-900 dark:text-white mb-1">
              {season}
            </h4>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              {values.count} crops
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};