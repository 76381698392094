import React, { useState, useEffect } from 'react';
import { Droplets, Timer, BarChart, Settings, AlertTriangle, Loader2, Cloud, ThermometerSun, Wind } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { getIrrigationData, updateIrrigationData } from '../services/database';
import { useStore } from '../store/useStore';
import type { IrrigationData } from '../types';

const IRRIGATION_METHODS = [
  { id: 'drip', name: 'Drip Irrigation', efficiency: 0.95 },
  { id: 'sprinkler', name: 'Sprinkler System', efficiency: 0.85 },
  { id: 'surface', name: 'Surface Irrigation', efficiency: 0.65 },
  { id: 'subsurface', name: 'Subsurface Irrigation', efficiency: 0.90 }
];

export const IrrigationManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [irrigationData, setIrrigationData] = useState<IrrigationData | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedMethod, setSelectedMethod] = useState(IRRIGATION_METHODS[0].id);
  const [schedule, setSchedule] = useState({
    time: '06:00',
    frequency: 'daily',
    duration: 30,
    zones: [
      { name: 'Zone 1', active: true, duration: 20 },
      { name: 'Zone 2', active: true, duration: 15 },
      { name: 'Zone 3', active: false, duration: 0 }
    ]
  });
  const { user, loading: authLoading } = useAuth();
  const { selectedLocation, weather } = useStore();

  useEffect(() => {
    const fetchIrrigationData = async () => {
      if (!authLoading && user?.uid) {
        try {
          setLoading(true);
          const data = await getIrrigationData(user.uid);
          if (data) {
            setIrrigationData(data);
          }
        } catch (error) {
          console.error('Failed to fetch irrigation data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchIrrigationData();
  }, [user?.uid, selectedLocation?.id, authLoading]);

  const handleScheduleUpdate = async () => {
    if (!user?.uid || !irrigationData) return;

    const nextScheduled = new Date();
    const [hours, minutes] = schedule.time.split(':');
    nextScheduled.setHours(parseInt(hours), parseInt(minutes));

    if (nextScheduled < new Date()) {
      nextScheduled.setDate(nextScheduled.getDate() + 1);
    }

    const updatedData: IrrigationData = {
      ...irrigationData,
      nextScheduled: nextScheduled.toISOString(),
      lastWatered: new Date().toISOString()
    };

    try {
      await updateIrrigationData(user.uid, updatedData);
      setIrrigationData(updatedData);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Failed to update irrigation schedule:', error);
    }
  };

  const calculateWaterNeeds = () => {
    if (!weather) return null;

    const baseNeed = 5; // Base water need in mm/day
    const temperatureFactor = Math.max(0, (weather.temperature - 20) * 0.2);
    const humidityFactor = Math.max(0, (70 - weather.humidity) * 0.02);
    const selectedMethodEfficiency = IRRIGATION_METHODS.find(m => m.id === selectedMethod)?.efficiency || 1;

    return {
      dailyNeed: Math.round((baseNeed + temperatureFactor + humidityFactor) / selectedMethodEfficiency * 100) / 100,
      weeklyNeed: Math.round((baseNeed + temperatureFactor + humidityFactor) / selectedMethodEfficiency * 7 * 100) / 100
    };
  };

  const waterNeeds = calculateWaterNeeds();

  if (authLoading || loading) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-center h-48">
          <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
        </div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <div className="text-center py-8 text-gray-500 dark:text-gray-400">
          Please sign in to view irrigation management.
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white flex items-center">
          <Droplets className="h-6 w-6 mr-2 text-blue-600" />
          Irrigation Management
        </h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className="flex items-center px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors"
        >
          <Settings className="h-4 w-4 mr-2" />
          Adjust Schedule
        </button>
      </div>

      {/* Current Conditions */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <div className="bg-blue-50 dark:bg-blue-900/20 rounded-lg p-4">
          <div className="flex items-center justify-between mb-2">
            <span className="text-sm font-medium text-blue-700 dark:text-blue-300">Temperature</span>
            <ThermometerSun className="h-5 w-5 text-blue-500" />
          </div>
          <p className="text-2xl font-bold text-blue-700 dark:text-blue-300">
            {weather?.temperature}°C
          </p>
        </div>

        <div className="bg-green-50 dark:bg-green-900/20 rounded-lg p-4">
          <div className="flex items-center justify-between mb-2">
            <span className="text-sm font-medium text-green-700 dark:text-green-300">Humidity</span>
            <Cloud className="h-5 w-5 text-green-500" />
          </div>
          <p className="text-2xl font-bold text-green-700 dark:text-green-300">
            {weather?.humidity}%
          </p>
        </div>

        <div className="bg-purple-50 dark:bg-purple-900/20 rounded-lg p-4">
          <div className="flex items-center justify-between mb-2">
            <span className="text-sm font-medium text-purple-700 dark:text-purple-300">Wind Speed</span>
            <Wind className="h-5 w-5 text-purple-500" />
          </div>
          <p className="text-2xl font-bold text-purple-700 dark:text-purple-300">
            {Math.round(Math.random() * 15)} km/h
          </p>
        </div>
      </div>

      {/* Irrigation Method Selection */}
      <div className="mb-6">
        <h3 className="text-lg font-medium text-gray-800 dark:text-white mb-4">
          Irrigation Method
        </h3>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {IRRIGATION_METHODS.map((method) => (
            <button
              key={method.id}
              onClick={() => setSelectedMethod(method.id)}
              className={`p-4 rounded-lg border transition-colors ${
                selectedMethod === method.id
                  ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20'
                  : 'border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700'
              }`}
            >
              <h4 className="font-medium text-gray-900 dark:text-white mb-1">
                {method.name}
              </h4>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {Math.round(method.efficiency * 100)}% efficient
              </p>
            </button>
          ))}
        </div>
      </div>

      {/* Water Requirements */}
      {waterNeeds && (
        <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-6 mb-6">
          <h3 className="text-lg font-medium text-gray-800 dark:text-white mb-4">
            Estimated Water Requirements
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">Daily Water Need</p>
              <p className="text-3xl font-bold text-blue-600 dark:text-blue-400">
                {waterNeeds.dailyNeed} mm
              </p>
            </div>
            <div>
              <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">Weekly Water Need</p>
              <p className="text-3xl font-bold text-blue-600 dark:text-blue-400">
                {waterNeeds.weeklyNeed} mm
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Zone Management */}
      <div className="mb-6">
        <h3 className="text-lg font-medium text-gray-800 dark:text-white mb-4">
          Irrigation Zones
        </h3>
        <div className="space-y-4">
          {schedule.zones.map((zone, index) => (
            <div
              key={index}
              className="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-700/50 rounded-lg"
            >
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={zone.active}
                  onChange={(e) => {
                    const newZones = [...schedule.zones];
                    newZones[index].active = e.target.checked;
                    setSchedule({ ...schedule, zones: newZones });
                  }}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <span className="ml-3 text-gray-700 dark:text-gray-300">{zone.name}</span>
              </div>
              <div className="flex items-center space-x-4">
                <input
                  type="number"
                  value={zone.duration}
                  onChange={(e) => {
                    const newZones = [...schedule.zones];
                    newZones[index].duration = parseInt(e.target.value) || 0;
                    setSchedule({ ...schedule, zones: newZones });
                  }}
                  className="w-20 px-2 py-1 border dark:border-gray-600 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
                  placeholder="Minutes"
                  min="0"
                  disabled={!zone.active}
                />
                <span className="text-sm text-gray-500 dark:text-gray-400">min</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Schedule Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-md">
            <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
              Adjust Irrigation Schedule
            </h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Watering Time
                </label>
                <input
                  type="time"
                  value={schedule.time}
                  onChange={(e) => setSchedule({ ...schedule, time: e.target.value })}
                  className="w-full px-3 py-2 border dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Frequency
                </label>
                <select
                  value={schedule.frequency}
                  onChange={(e) => setSchedule({ ...schedule, frequency: e.target.value })}
                  className="w-full px-3 py-2 border dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                >
                  <option value="daily">Daily</option>
                  <option value="alternate">Alternate Days</option>
                  <option value="weekly">Weekly</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Duration (minutes)
                </label>
                <input
                  type="number"
                  value={schedule.duration}
                  onChange={(e) => setSchedule({ ...schedule, duration: parseInt(e.target.value) })}
                  min="1"
                  max="120"
                  className="w-full px-3 py-2 border dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                />
              </div>
              <div className="flex justify-end space-x-3 mt-6">
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="px-4 py-2 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={handleScheduleUpdate}
                  className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors"
                >
                  Update Schedule
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};