import React, { useEffect, useState } from 'react';
import { Cloud, Droplets, Sun, ThermometerSun, Wind } from 'lucide-react';
import { format } from 'date-fns';
import { useStore } from '../store/useStore';
import { fetchWeatherData } from '../services/weather';
import type { Weather } from '../types';

export const WeatherDashboard = () => {
  const { selectedLocation } = useStore();
  const [weatherData, setWeatherData] = useState<Weather | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getWeatherData = async () => {
      if (!selectedLocation) return;

      try {
        setLoading(true);
        setError(null);
        const data = await fetchWeatherData(selectedLocation);
        setWeatherData(data);
      } catch (err) {
        setError('Failed to fetch weather data. Please try again later.');
        console.error('Weather fetch error:', err);
      } finally {
        setLoading(false);
      }
    };

    getWeatherData();
  }, [selectedLocation]);

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 dark:bg-red-900/20 p-4 rounded-lg">
        <p className="text-red-600 dark:text-red-400">{error}</p>
      </div>
    );
  }

  if (!weatherData || !selectedLocation) {
    return (
      <div className="bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-lg">
        <p className="text-yellow-600 dark:text-yellow-400">
          Please select a location to view weather data.
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        <div className="bg-gradient-to-br from-blue-500 to-blue-600 p-6 rounded-xl text-white">
          <div className="flex items-center gap-2 mb-2">
            <ThermometerSun className="h-6 w-6" />
            <span className="font-medium">Temperature</span>
          </div>
          <p className="text-3xl font-bold">{weatherData.temperature}°C</p>
        </div>

        <div className="bg-gradient-to-br from-green-500 to-green-600 p-6 rounded-xl text-white">
          <div className="flex items-center gap-2 mb-2">
            <Droplets className="h-6 w-6" />
            <span className="font-medium">Humidity</span>
          </div>
          <p className="text-3xl font-bold">{weatherData.humidity}%</p>
        </div>

        <div className="bg-gradient-to-br from-purple-500 to-purple-600 p-6 rounded-xl text-white">
          <div className="flex items-center gap-2 mb-2">
            <Wind className="h-6 w-6" />
            <span className="font-medium">Rainfall</span>
          </div>
          <p className="text-3xl font-bold">{weatherData.rainfall} mm</p>
        </div>

        <div className="bg-gradient-to-br from-orange-500 to-orange-600 p-6 rounded-xl text-white">
          <div className="flex items-center gap-2 mb-2">
            <Cloud className="h-6 w-6" />
            <span className="font-medium">Location</span>
          </div>
          <p className="text-xl font-bold truncate">{selectedLocation.name}</p>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
        <h3 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">7-Day Forecast</h3>
        <div className="grid grid-cols-2 md:grid-cols-7 gap-4">
          {weatherData.forecast.map((day, index) => (
            <div key={index} className="bg-gray-50 dark:bg-gray-700/50 p-4 rounded-lg text-center">
              <p className="text-sm font-medium text-gray-600 dark:text-gray-300 mb-2">
                {format(new Date(day.date), 'EEE')}
              </p>
              <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                {format(new Date(day.date), 'MMM d')}
              </p>
              <div className="flex justify-center mb-2">
                <Cloud className="h-6 w-6 text-gray-500" />
              </div>
              <div className="space-y-1">
                <p className="text-sm font-bold text-gray-900 dark:text-white">
                  {day.temperature.max}°C
                </p>
                <p className="text-xs text-gray-600 dark:text-gray-400">
                  {day.temperature.min}°C
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};