import { create } from 'zustand';
import { Weather, PlantingEvent, IrrigationData, Location } from '../types';

interface Store {
  weather: Weather | null;
  plantingEvents: PlantingEvent[];
  irrigationData: IrrigationData | null;
  isDarkMode: boolean;
  selectedLocation: Location | null;
  setWeather: (weather: Weather) => void;
  addPlantingEvent: (event: PlantingEvent) => void;
  setIrrigationData: (data: IrrigationData) => void;
  toggleDarkMode: () => void;
  setSelectedLocation: (location: Location) => void;
}

export const useStore = create<Store>((set) => ({
  weather: null,
  plantingEvents: [],
  irrigationData: null,
  isDarkMode: window.matchMedia('(prefers-color-scheme: dark)').matches,
  selectedLocation: null,
  setWeather: (weather) => set({ weather }),
  addPlantingEvent: (event) =>
    set((state) => ({ plantingEvents: [...state.plantingEvents, event] })),
  setIrrigationData: (data) => set({ irrigationData: data }),
  toggleDarkMode: () => 
    set((state) => {
      const isDarkMode = !state.isDarkMode;
      document.documentElement.classList.toggle('dark', isDarkMode);
      return { isDarkMode };
    }),
  setSelectedLocation: (location) => set({ selectedLocation: location }),
}));