import React, { useState } from 'react';
import { Calculator, ArrowRight } from 'lucide-react';

type CalculationType = 'seed' | 'fertilizer' | 'yield' | 'water' | 'roi';

interface CalculationField {
  label: string;
  unit: string;
  value: string;
}

export const FarmCalculator: React.FC = () => {
  const [calculationType, setCalculationType] = useState<CalculationType>('seed');
  const [fields, setFields] = useState<Record<string, string>>({});
  const [result, setResult] = useState<string | null>(null);

  const calculations: Record<CalculationType, {
    title: string;
    description: string;
    fields: CalculationField[];
    calculate: (values: Record<string, number>) => { result: number; unit: string };
  }> = {
    seed: {
      title: 'Seed Requirement',
      description: 'Calculate the amount of seeds needed for your field',
      fields: [
        { label: 'Field Area', unit: 'acres', value: 'area' },
        { label: 'Seed Rate', unit: 'kg/acre', value: 'rate' }
      ],
      calculate: (values) => ({
        result: values.area * values.rate,
        unit: 'kg'
      })
    },
    fertilizer: {
      title: 'Fertilizer Calculator',
      description: 'Calculate fertilizer requirements based on soil needs',
      fields: [
        { label: 'Field Area', unit: 'acres', value: 'area' },
        { label: 'Application Rate', unit: 'kg/acre', value: 'rate' }
      ],
      calculate: (values) => ({
        result: values.area * values.rate,
        unit: 'kg'
      })
    },
    yield: {
      title: 'Yield Estimator',
      description: 'Estimate crop yield based on field parameters',
      fields: [
        { label: 'Field Area', unit: 'acres', value: 'area' },
        { label: 'Average Yield', unit: 'tons/acre', value: 'yield' }
      ],
      calculate: (values) => ({
        result: values.area * values.yield,
        unit: 'tons'
      })
    },
    water: {
      title: 'Water Requirement',
      description: 'Calculate water needs for irrigation',
      fields: [
        { label: 'Field Area', unit: 'acres', value: 'area' },
        { label: 'Water Need', unit: 'mm/day', value: 'need' },
        { label: 'Days', unit: 'days', value: 'days' }
      ],
      calculate: (values) => ({
        result: values.area * values.need * values.days * 4046.86 / 1000, // Convert to cubic meters
        unit: 'm³'
      })
    },
    roi: {
      title: 'ROI Calculator',
      description: 'Calculate return on investment',
      fields: [
        { label: 'Total Cost', unit: '₹', value: 'cost' },
        { label: 'Expected Revenue', unit: '₹', value: 'revenue' }
      ],
      calculate: (values) => ({
        result: ((values.revenue - values.cost) / values.cost) * 100,
        unit: '%'
      })
    }
  };

  const handleCalculate = () => {
    const currentCalc = calculations[calculationType];
    const values: Record<string, number> = {};
    
    for (const field of currentCalc.fields) {
      const value = Number(fields[field.value]);
      if (isNaN(value)) {
        setResult('Please enter valid numbers');
        return;
      }
      values[field.value] = value;
    }

    const { result, unit } = currentCalc.calculate(values);
    setResult(`${result.toFixed(2)} ${unit}`);
  };

  const handleInputChange = (fieldValue: string, value: string) => {
    setFields(prev => ({
      ...prev,
      [fieldValue]: value
    }));
    setResult(null);
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white flex items-center">
        <Calculator className="h-6 w-6 mr-2 text-blue-600" />
        Farm Calculator
      </h2>

      <div className="grid grid-cols-2 md:grid-cols-5 gap-2 mb-6">
        {(Object.keys(calculations) as CalculationType[]).map((type) => (
          <button
            key={type}
            onClick={() => {
              setCalculationType(type);
              setFields({});
              setResult(null);
            }}
            className={`p-3 rounded-lg transition-colors ${
              calculationType === type
                ? 'bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400'
                : 'hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-600 dark:text-gray-400'
            }`}
          >
            {calculations[type].title}
          </button>
        ))}
      </div>

      <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-6">
        <h3 className="text-lg font-semibold mb-2 text-gray-800 dark:text-white">
          {calculations[calculationType].title}
        </h3>
        <p className="text-gray-600 dark:text-gray-400 mb-4">
          {calculations[calculationType].description}
        </p>

        <div className="space-y-4">
          {calculations[calculationType].fields.map((field) => (
            <div key={field.value}>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                {field.label} ({field.unit})
              </label>
              <input
                type="number"
                value={fields[field.value] || ''}
                onChange={(e) => handleInputChange(field.value, e.target.value)}
                className="w-full px-3 py-2 border dark:border-gray-600 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
                placeholder={`Enter ${field.label.toLowerCase()}`}
              />
            </div>
          ))}

          <button
            onClick={handleCalculate}
            className="w-full mt-4 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors flex items-center justify-center"
          >
            Calculate
            <ArrowRight className="h-4 w-4 ml-2" />
          </button>

          {result && (
            <div className="mt-4 p-4 bg-blue-50 dark:bg-blue-900/20 rounded-lg">
              <div className="text-center">
                <span className="text-sm text-blue-600 dark:text-blue-400">Result:</span>
                <div className="text-2xl font-bold text-blue-700 dark:text-blue-300">
                  {result}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};