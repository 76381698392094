import React, { useState, useEffect } from 'react';
import { BookOpen, Search, Video, AlertCircle, ExternalLink } from 'lucide-react';
import { searchFarmingVideos } from '../services/youtube';
import { DiseaseIdentification } from './DiseaseIdentification';
import { VideoPlayer } from './VideoPlayer';

interface Video {
  id: string;
  title: string;
  thumbnail: string;
  description: string;
  publishedAt: string;
}

const KnowledgeBase: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [videos, setVideos] = useState<Video[]>([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<'videos' | 'diseases'>('videos');
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  useEffect(() => {
    const fetchVideos = async () => {
      if (searchQuery.trim()) {
        try {
          setLoading(true);
          const results = await searchFarmingVideos(searchQuery);
          setVideos(results);
        } catch (error) {
          console.error('Failed to fetch videos:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setVideos([]);
      }
    };

    const debounce = setTimeout(fetchVideos, 500);
    return () => clearTimeout(debounce);
  }, [searchQuery]);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white flex items-center">
        <BookOpen className="h-6 w-6 mr-2 text-purple-600" />
        Knowledge Base
      </h2>

      <div className="flex space-x-4 mb-6">
        <button
          onClick={() => setActiveTab('videos')}
          className={`flex-1 py-2 px-4 rounded-lg font-medium transition-colors ${
            activeTab === 'videos'
              ? 'bg-purple-100 dark:bg-purple-900/20 text-purple-700 dark:text-purple-300'
              : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700'
          }`}
        >
          <Video className="h-5 w-5 inline mr-2" />
          Tutorial Videos
        </button>
        <button
          onClick={() => setActiveTab('diseases')}
          className={`flex-1 py-2 px-4 rounded-lg font-medium transition-colors ${
            activeTab === 'diseases'
              ? 'bg-red-100 dark:bg-red-900/20 text-red-700 dark:text-red-300'
              : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700'
          }`}
        >
          <AlertCircle className="h-5 w-5 inline mr-2" />
          Disease Identification
        </button>
      </div>

      {activeTab === 'videos' ? (
        <>
          <div className="mb-6">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search farming tutorials..."
                className="w-full pl-10 pr-4 py-2 border dark:border-gray-700 rounded-lg bg-white dark:bg-gray-900 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              />
            </div>
          </div>

          {loading ? (
            <div className="text-center py-4">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500 mx-auto"></div>
            </div>
          ) : (
            <div className="space-y-4">
              {selectedVideo && (
                <div className="mb-4">
                  <VideoPlayer videoId={selectedVideo} />
                </div>
              )}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {videos.map((video) => (
                  <div
                    key={video.id}
                    onClick={() => setSelectedVideo(video.id)}
                    className="group block bg-gray-50 dark:bg-gray-900 rounded-lg overflow-hidden hover:shadow-md transition-shadow cursor-pointer"
                  >
                    <div className="aspect-video relative">
                      <img
                        src={video.thumbnail}
                        alt={video.title}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-10 transition-opacity"></div>
                    </div>
                    <div className="p-4">
                      <h4 className="text-sm font-medium text-gray-900 dark:text-white line-clamp-2 mb-1 group-hover:text-purple-600 dark:group-hover:text-purple-400">
                        {video.title}
                      </h4>
                      <div className="flex items-center text-xs text-gray-500 dark:text-gray-400">
                        <Video className="h-3 w-3 mr-1" />
                        Click to play
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <DiseaseIdentification />
      )}
    </div>
  );
};

export { KnowledgeBase };