interface ApiConfig {
  openai: {
    apiKey: string;
    model: string;
  };
  youtube: {
    apiKey: string;
  };
  openWeatherMap: {
    apiKey: string;
  };
  plantId: {
    apiKey: string;
  };
}

export const apiConfig: ApiConfig = {
  openai: {
    apiKey: import.meta.env.VITE_OPENAI_API_KEY || '',
    model: 'gpt-4-turbo-preview',
  },
  youtube: {
    apiKey: 'AIzaSyBdwdo4AVDHGCaQ7eikQHYDntVK6OvwOFg',
  },
  openWeatherMap: {
    apiKey: import.meta.env.VITE_OPENWEATHER_API_KEY || '',
  },
  plantId: {
    apiKey: '6MswlFQ8LsZHqLBt4ikvfJX5l06BH93aoigpPSZuLJLt4dPZrw',
  },
};