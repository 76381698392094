import React, { useState, useEffect } from 'react';
import { Calendar, Sprout, Clock, Plus, Trash2, Loader2 } from 'lucide-react';
import { format, addDays } from 'date-fns';
import { useAuth } from '../hooks/useAuth';
import { getPlantingEvents, addPlantingEvent, deletePlantingEvent } from '../services/database';
import type { PlantingEvent, Crop } from '../types';
import { useStore } from '../store/useStore';

const SAMPLE_CROPS: Crop[] = [
  { id: 'tomatoes', name: 'Tomatoes', growthDuration: 80, phRange: { min: 6.0, max: 6.8 }, tempRange: { min: 20, max: 30 }, rainfall: { min: 400, max: 600 }, season: ['Spring', 'Summer'] },
  { id: 'carrots', name: 'Carrots', growthDuration: 70, phRange: { min: 6.0, max: 6.8 }, tempRange: { min: 15, max: 25 }, rainfall: { min: 350, max: 500 }, season: ['Spring', 'Fall'] },
  { id: 'lettuce', name: 'Lettuce', growthDuration: 45, phRange: { min: 6.0, max: 7.0 }, tempRange: { min: 15, max: 22 }, rainfall: { min: 300, max: 400 }, season: ['Spring', 'Fall'] },
];

export const PlantingCalendar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCrop, setSelectedCrop] = useState('');
  const [plantingDate, setPlantingDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [events, setEvents] = useState<PlantingEvent[]>([]);
  const [loading, setLoading] = useState(true);
  const { user, loading: authLoading } = useAuth();
  const { selectedLocation } = useStore();

  useEffect(() => {
    const fetchEvents = async () => {
      if (!authLoading && user?.uid) {
        try {
          setLoading(true);
          const fetchedEvents = await getPlantingEvents(user.uid);
          setEvents(fetchedEvents);
        } catch (error) {
          console.error('Failed to fetch planting events:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchEvents();
  }, [user?.uid, selectedLocation?.id, authLoading]);

  const handleAddEvent = async () => {
    if (!selectedCrop || !plantingDate || !user) return;

    const crop = SAMPLE_CROPS.find(c => c.id === selectedCrop);
    if (!crop) return;

    const newEvent: Omit<PlantingEvent, 'id'> = {
      cropId: selectedCrop,
      plantingDate,
      expectedHarvestDate: format(addDays(new Date(plantingDate), crop.growthDuration), 'yyyy-MM-dd'),
      status: 'Planned'
    };

    try {
      const eventId = await addPlantingEvent(newEvent, user.uid);
      setEvents([...events, { ...newEvent, id: eventId }]);
      setIsModalOpen(false);
      setSelectedCrop('');
      setPlantingDate(format(new Date(), 'yyyy-MM-dd'));
    } catch (error) {
      console.error('Failed to add planting event:', error);
    }
  };

  const handleDeleteEvent = async (eventId: string) => {
    try {
      await deletePlantingEvent(eventId);
      setEvents(events.filter(event => event.id !== eventId));
    } catch (error) {
      console.error('Failed to delete planting event:', error);
    }
  };

  if (authLoading || loading) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-center h-48">
          <Loader2 className="h-8 w-8 animate-spin text-green-500" />
        </div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <div className="text-center py-8 text-gray-500 dark:text-gray-400">
          Please sign in to view your planting calendar.
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-white flex items-center">
          <Calendar className="h-6 w-6 mr-2 text-green-600" />
          Planting Calendar
        </h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className="flex items-center px-4 py-2 bg-green-600 hover:bg-green-700 text-white rounded-lg transition-colors"
        >
          <Plus className="h-4 w-4 mr-2" />
          Add Event
        </button>
      </div>

      <div className="space-y-4">
        {events.length === 0 ? (
          <div className="text-center py-8 text-gray-500 dark:text-gray-400">
            No planting events scheduled. Click "Add Event" to create one.
          </div>
        ) : (
          events.map((event) => {
            const crop = SAMPLE_CROPS.find(c => c.id === event.cropId);
            return (
              <div
                key={event.id}
                className="border dark:border-gray-700 rounded-lg p-4 hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-4">
                    <Sprout className="h-5 w-5 text-green-600" />
                    <div>
                      <h3 className="font-medium text-gray-900 dark:text-white">
                        {crop?.name || event.cropId}
                      </h3>
                      <div className="flex items-center mt-1 text-sm text-gray-500 dark:text-gray-400">
                        <Clock className="h-4 w-4 mr-1" />
                        <span>
                          Planted: {format(new Date(event.plantingDate), 'MMM d, yyyy')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Harvest: {format(new Date(event.expectedHarvestDate), 'MMM d, yyyy')}
                    </span>
                    <button
                      onClick={() => handleDeleteEvent(event.id)}
                      className="p-1 text-red-500 hover:text-red-600 transition-colors"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-md">
            <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
              Add Planting Event
            </h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Crop
                </label>
                <select
                  value={selectedCrop}
                  onChange={(e) => setSelectedCrop(e.target.value)}
                  className="w-full px-3 py-2 border dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                >
                  <option value="">Select a crop</option>
                  {SAMPLE_CROPS.map((crop) => (
                    <option key={crop.id} value={crop.id}>
                      {crop.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Planting Date
                </label>
                <input
                  type="date"
                  value={plantingDate}
                  onChange={(e) => setPlantingDate(e.target.value)}
                  className="w-full px-3 py-2 border dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                />
              </div>
              <div className="flex justify-end space-x-3 mt-6">
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="px-4 py-2 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={handleAddEvent}
                  className="px-4 py-2 bg-green-600 hover:bg-green-700 text-white rounded-lg transition-colors"
                >
                  Add Event
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};