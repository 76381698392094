import { 
  collection, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  doc, 
  query, 
  where, 
  getDocs,
  setDoc,
  serverTimestamp,
  DocumentData,
  QueryDocumentSnapshot 
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { PlantingEvent, IrrigationData } from '../types';

const formatDoc = (doc: QueryDocumentSnapshot<DocumentData>) => ({
  id: doc.id,
  ...doc.data()
});

export const getPlantingEvents = async (userId: string): Promise<PlantingEvent[]> => {
  if (!userId) return [];
  
  try {
    const q = query(collection(db, 'plantingEvents'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(formatDoc) as PlantingEvent[];
  } catch (error) {
    console.error('Error fetching planting events:', error);
    return [];
  }
};

export const addPlantingEvent = async (event: Omit<PlantingEvent, 'id'>, userId: string): Promise<string> => {
  if (!userId) throw new Error('User ID is required');

  try {
    const docRef = await addDoc(collection(db, 'plantingEvents'), {
      ...event,
      userId,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });
    return docRef.id;
  } catch (error) {
    console.error('Error adding planting event:', error);
    throw error;
  }
};

export const updatePlantingEvent = async (id: string, userId: string, data: Partial<PlantingEvent>): Promise<void> => {
  if (!userId) throw new Error('User ID is required');

  try {
    const docRef = doc(db, 'plantingEvents', id);
    await updateDoc(docRef, {
      ...data,
      userId,
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error updating planting event:', error);
    throw error;
  }
};

export const deletePlantingEvent = async (id: string, userId: string): Promise<void> => {
  if (!userId) throw new Error('User ID is required');

  try {
    const docRef = doc(db, 'plantingEvents', id);
    await deleteDoc(docRef);
  } catch (error) {
    console.error('Error deleting planting event:', error);
    throw error;
  }
};

export const getIrrigationData = async (userId: string): Promise<IrrigationData | null> => {
  if (!userId) return null;

  try {
    const docRef = doc(db, 'irrigation', userId);
    const querySnapshot = await getDocs(query(collection(db, 'irrigation'), where('userId', '==', userId)));
    const doc = querySnapshot.docs[0];
    
    if (!doc) {
      // Initialize default irrigation data
      const defaultData: IrrigationData = {
        soilMoisture: 50,
        lastWatered: new Date().toISOString(),
        nextScheduled: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(), // Next day
        waterUsage: 0,
        userId,
        createdAt: new Date(),
        updatedAt: new Date()
      };
      
      await setDoc(docRef, defaultData);
      return defaultData;
    }
    
    return formatDoc(doc) as IrrigationData;
  } catch (error) {
    console.error('Error fetching irrigation data:', error);
    return null;
  }
};

export const updateIrrigationData = async (userId: string, data: IrrigationData): Promise<void> => {
  if (!userId) throw new Error('User ID is required');

  try {
    const docRef = doc(db, 'irrigation', userId);
    await setDoc(docRef, {
      ...data,
      userId,
      updatedAt: serverTimestamp()
    }, { merge: true });
  } catch (error) {
    console.error('Error updating irrigation data:', error);
    throw error;
  }
};