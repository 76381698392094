import React, { useState, useRef } from 'react';
import { UploadCloud, X, Loader2, Leaf, AlertCircle, CheckCircle } from 'lucide-react';
import { analyzePlantImage } from '../services/plantId';

interface Analysis {
  disease?: {
    name: string;
    probability: number;
    description: string;
    treatment: string;
  };
  health: {
    isHealthy: boolean;
    score: number;
  };
  plant?: {
    name: string;
    probability: number;
  };
}

export const DiseaseIdentification = () => {
  const [image, setImage] = useState<string | null>(null);
  const [analysis, setAnalysis] = useState<Analysis | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      setError('Please upload an image file (JPEG, PNG, etc.).');
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      setError('Image size should be less than 5MB.');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const reader = new FileReader();
      reader.onload = async () => {
        if (!reader.result) {
          throw new Error('Failed to read image file.');
        }

        const base64String = (reader.result as string).split(',')[1];
        setImage(reader.result as string);

        try {
          const result = await analyzePlantImage(base64String);
          setAnalysis(result);
        } catch (error) {
          setError(error instanceof Error ? error.message : 'Failed to analyze image.');
          console.error('Analysis error:', error);
        } finally {
          setLoading(false);
        }
      };

      reader.onerror = () => {
        setError('Failed to read image file. Please try again.');
        setLoading(false);
      };

      reader.readAsDataURL(file);
    } catch (error) {
      setError('Failed to process image. Please try again.');
      setLoading(false);
    }
  };

  const resetAnalysis = () => {
    setImage(null);
    setAnalysis(null);
    setError(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const getHealthColor = (score: number) => {
    if (score >= 70) return 'text-green-500';
    if (score >= 40) return 'text-yellow-500';
    return 'text-red-500';
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-center">
        <div className="relative w-full max-w-md">
          {!image ? (
            <>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleImageUpload}
                accept="image/*"
                className="hidden"
                id="image-upload"
              />
              <label
                htmlFor="image-upload"
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg cursor-pointer bg-gray-50 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 transition-all duration-200"
              >
                <UploadCloud className="h-12 w-12 text-gray-400 mb-2" />
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Upload a plant image for analysis
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-500 mt-1">
                  Supported formats: JPEG, PNG (max 5MB)
                </p>
              </label>
            </>
          ) : (
            <div className="relative rounded-lg overflow-hidden shadow-lg">
              <img
                src={image}
                alt="Uploaded plant"
                className="w-full h-64 object-cover"
              />
              <button
                onClick={resetAnalysis}
                className="absolute top-2 right-2 p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
                aria-label="Remove image"
              >
                <X className="h-4 w-4" />
              </button>
            </div>
          )}
        </div>
      </div>

      {loading && (
        <div className="flex items-center justify-center space-x-2 text-primary-500">
          <Loader2 className="h-5 w-5 animate-spin" />
          <span className="text-gray-600 dark:text-gray-400">
            Analyzing image...
          </span>
        </div>
      )}

      {error && (
        <div className="flex items-center justify-center space-x-2 text-red-500 bg-red-50 dark:bg-red-900/20 p-3 rounded-lg">
          <AlertCircle className="h-5 w-5 flex-shrink-0" />
          <span className="text-sm">{error}</span>
        </div>
      )}

      {analysis && (
        <div className="space-y-4">
          <div className="p-6 rounded-lg bg-white dark:bg-gray-800 shadow-lg">
            <div className="flex items-center space-x-2 mb-4">
              <Leaf className="h-5 w-5 text-green-500" />
              <h3 className="font-medium text-gray-900 dark:text-white text-lg">
                Analysis Results
              </h3>
            </div>

            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <span className="text-gray-600 dark:text-gray-400">Health Score</span>
                <div className="flex items-center space-x-2">
                  {analysis.health.isHealthy ? (
                    <CheckCircle className="h-5 w-5 text-green-500" />
                  ) : (
                    <AlertCircle className="h-5 w-5 text-yellow-500" />
                  )}
                  <span className={`font-medium ${getHealthColor(analysis.health.score)}`}>
                    {analysis.health.score}%
                  </span>
                </div>
              </div>

              {analysis.plant && (
                <div className="border-t dark:border-gray-700 pt-4">
                  <h4 className="font-medium text-gray-900 dark:text-white mb-2">
                    Plant Identification
                  </h4>
                  <p className="text-gray-600 dark:text-gray-400">
                    {analysis.plant.name}
                    <span className="text-sm text-gray-500 dark:text-gray-500 ml-2">
                      ({Math.round(analysis.plant.probability * 100)}% confidence)
                    </span>
                  </p>
                </div>
              )}

              {analysis.disease && (
                <div className="border-t dark:border-gray-700 pt-4 space-y-3">
                  <div>
                    <h4 className="font-medium text-red-500 mb-2 flex items-center">
                      <AlertCircle className="h-4 w-4 mr-2" />
                      {analysis.disease.name}
                    </h4>
                    <p className="text-gray-600 dark:text-gray-400 text-sm">
                      {analysis.disease.description}
                    </p>
                  </div>

                  <div>
                    <h4 className="font-medium text-green-500 mb-2 flex items-center">
                      <CheckCircle className="h-4 w-4 mr-2" />
                      Recommended Treatment
                    </h4>
                    <p className="text-gray-600 dark:text-gray-400 text-sm">
                      {analysis.disease.treatment}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};