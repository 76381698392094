import { db } from '../lib/firebase';
import { collection, doc, setDoc, getDoc } from 'firebase/firestore';
import type { Weather, Location } from '../types';
import { apiConfig } from '../config/apis';

const generateWeatherWithAI = async (location: Location): Promise<Weather> => {
  if (!apiConfig.openai.apiKey) {
    return generateSimulatedWeather(location);
  }

  try {
    const prompt = `Generate realistic weather data for ${location.name}, ${location.country} with climate type ${location.climate}. Consider average temperature ${location.avgTemperature}°C and average rainfall ${location.avgRainfall}mm. Include current conditions and 7-day forecast.`;

    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiConfig.openai.apiKey}`
      },
      body: JSON.stringify({
        model: apiConfig.openai.model,
        messages: [{
          role: 'user',
          content: prompt
        }],
        temperature: 0.7,
        functions: [{
          name: 'processWeatherData',
          parameters: {
            type: 'object',
            properties: {
              current: {
                type: 'object',
                properties: {
                  temperature: { type: 'number' },
                  humidity: { type: 'number' },
                  rainfall: { type: 'number' }
                },
                required: ['temperature', 'humidity', 'rainfall']
              },
              forecast: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    date: { type: 'string' },
                    temperature: {
                      type: 'object',
                      properties: {
                        min: { type: 'number' },
                        max: { type: 'number' }
                      },
                      required: ['min', 'max']
                    },
                    rainfall: { type: 'number' },
                    humidity: { type: 'number' }
                  },
                  required: ['date', 'temperature', 'rainfall', 'humidity']
                }
              }
            },
            required: ['current', 'forecast']
          }
        }],
        function_call: { name: 'processWeatherData' }
      })
    });

    if (!response.ok) {
      throw new Error(`OpenAI API error: ${response.statusText}`);
    }

    const data = await response.json();
    
    if (!data.choices?.[0]?.message?.function_call?.arguments) {
      throw new Error('Invalid response format from OpenAI API');
    }

    const weatherData = JSON.parse(data.choices[0].message.function_call.arguments);

    const weather: Weather = {
      temperature: weatherData.current.temperature,
      humidity: weatherData.current.humidity,
      rainfall: weatherData.current.rainfall,
      forecast: weatherData.forecast.map((day: any, index: number) => ({
        date: new Date(Date.now() + index * 24 * 60 * 60 * 1000).toISOString(),
        temperature: {
          min: day.temperature.min,
          max: day.temperature.max
        },
        rainfall: day.rainfall,
        humidity: day.humidity
      }))
    };

    if (db) {
      try {
        const weatherRef = doc(collection(db, 'weather'), location.id);
        await setDoc(weatherRef, { ...weather, timestamp: new Date() });
      } catch (error) {
        console.warn('Failed to save weather data to database:', error);
      }
    }

    return weather;
  } catch (error) {
    console.warn('Failed to generate weather with AI:', error);
    return generateSimulatedWeather(location);
  }
};

const generateSimulatedWeather = (location: Location): Weather => {
  const baseTemp = location.avgTemperature;
  const baseRain = location.avgRainfall / 365;
  const seasonalVariation = Math.sin((new Date().getMonth() / 12) * 2 * Math.PI);

  const currentTemp = baseTemp + seasonalVariation * 5 + (Math.random() * 4 - 2);
  const currentHumidity = Math.min(100, Math.max(30, 60 + seasonalVariation * 20 + Math.random() * 10));
  const currentRainfall = Math.max(0, baseRain * (1 + seasonalVariation + Math.random()));

  return {
    temperature: Math.round(currentTemp),
    humidity: Math.round(currentHumidity),
    rainfall: Math.round(currentRainfall * 10) / 10,
    forecast: Array.from({ length: 7 }, (_, i) => {
      const dayVariation = Math.sin(((i + new Date().getDay()) / 7) * 2 * Math.PI);
      const tempVariation = seasonalVariation * 5 + dayVariation * 3;
      
      return {
        date: new Date(Date.now() + i * 24 * 60 * 60 * 1000).toISOString(),
        temperature: {
          min: Math.round(baseTemp - 5 + tempVariation + (Math.random() * 2 - 1)),
          max: Math.round(baseTemp + 5 + tempVariation + (Math.random() * 2 - 1)),
        },
        rainfall: Math.max(0, Math.round(baseRain * (1 + dayVariation + Math.random()) * 10) / 10),
        humidity: Math.round(Math.min(100, Math.max(30, 60 + dayVariation * 20 + Math.random() * 10))),
      };
    }),
  };
};

export const fetchWeatherData = async (location: Location): Promise<Weather> => {
  if (!location) {
    throw new Error('Location is required for weather data');
  }

  try {
    if (db) {
      const weatherRef = doc(collection(db, 'weather'), location.id);
      const weatherDoc = await getDoc(weatherRef);
      
      if (weatherDoc.exists()) {
        const data = weatherDoc.data() as Weather & { timestamp: Date };
        const hoursSinceUpdate = (Date.now() - data.timestamp.getTime()) / (1000 * 60 * 60);
        
        if (hoursSinceUpdate < 1) {
          return data;
        }
      }
    }

    return await generateWeatherWithAI(location);
  } catch (error) {
    console.warn('Error fetching weather data:', error);
    return generateSimulatedWeather(location);
  }
};