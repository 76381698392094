import React, { useEffect, useRef, useState } from 'react';
import { Loader2, AlertCircle } from 'lucide-react';

declare global {
  interface Window {
    YT: any;
    onYouTubeIframeAPIReady: () => void;
  }
}

let apiLoaded = false;
let apiLoadPromise: Promise<void> | null = null;

const loadYouTubeAPI = (): Promise<void> => {
  if (apiLoaded) return Promise.resolve();
  if (apiLoadPromise) return apiLoadPromise;

  apiLoadPromise = new Promise((resolve) => {
    window.onYouTubeIframeAPIReady = () => {
      apiLoaded = true;
      resolve();
    };

    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);
  });

  return apiLoadPromise;
};

interface VideoPlayerProps {
  videoId: string;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoId }) => {
  const playerRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let mounted = true;
    let player: any = null;

    const initPlayer = async () => {
      if (!mounted) return;

      try {
        setIsLoading(true);
        setError(null);
        await loadYouTubeAPI();

        if (!mounted || !containerRef.current) return;

        // Create a dedicated player container
        const playerId = `youtube-player-${videoId}`;
        const playerContainer = document.createElement('div');
        playerContainer.id = playerId;
        containerRef.current.innerHTML = ''; // Clear previous content
        containerRef.current.appendChild(playerContainer);

        player = new window.YT.Player(playerId, {
          height: '100%',
          width: '100%',
          videoId,
          playerVars: {
            autoplay: 1,
            modestbranding: 1,
            rel: 0,
            enablejsapi: 1,
            playsinline: 1,
            controls: 1,
            fs: 1,
            origin: window.location.origin,
            widget_referrer: window.location.href,
            mute: 1 // Start muted to help with autoplay
          },
          events: {
            onReady: (event: any) => {
              if (mounted) {
                setIsLoading(false);
                try {
                  event.target.playVideo();
                  // Unmute after playback starts
                  setTimeout(() => {
                    try {
                      event.target.unMute();
                      event.target.setVolume(100);
                    } catch (e) {
                      console.warn('Failed to unmute:', e);
                    }
                  }, 1000);
                } catch (e) {
                  console.warn('Auto-play failed:', e);
                }
              }
            },
            onError: (event: any) => {
              if (!mounted) return;
              
              setIsLoading(false);
              const errorMessages: Record<number, string> = {
                2: 'Invalid video ID',
                5: 'HTML5 player error',
                100: 'Video not found or removed',
                101: 'Video playback not allowed in embedded players',
                150: 'Video playback not allowed in embedded players'
              };
              const errorMessage = errorMessages[event.data] || `Failed to load video (Error: ${event.data})`;
              console.error('YouTube player error:', errorMessage);
              setError(errorMessage);

              // Fallback to direct embed if API fails
              if (event.data === 101 || event.data === 150) {
                const iframe = document.createElement('iframe');
                iframe.src = `https://www.youtube.com/embed/${videoId}?autoplay=1&modestbranding=1&rel=0`;
                iframe.width = '100%';
                iframe.height = '100%';
                iframe.allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
                iframe.allowFullscreen = true;
                
                if (containerRef.current) {
                  containerRef.current.innerHTML = '';
                  containerRef.current.appendChild(iframe);
                }
                setError(null);
              }
            },
            onStateChange: (event: any) => {
              if (event.data === window.YT.PlayerState.PLAYING) {
                setIsLoading(false);
              }
            }
          }
        });

        playerRef.current = player;
      } catch (err) {
        if (mounted) {
          console.error('YouTube player initialization error:', err);
          // Fallback to direct embed if API fails
          if (containerRef.current) {
            const iframe = document.createElement('iframe');
            iframe.src = `https://www.youtube.com/embed/${videoId}?autoplay=1&modestbranding=1&rel=0`;
            iframe.width = '100%';
            iframe.height = '100%';
            iframe.allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
            iframe.allowFullscreen = true;
            containerRef.current.innerHTML = '';
            containerRef.current.appendChild(iframe);
          }
        }
      }
    };

    initPlayer();

    return () => {
      mounted = false;
      if (playerRef.current) {
        try {
          playerRef.current.destroy();
        } catch (err) {
          console.error('Error destroying player:', err);
        }
      }
    };
  }, [videoId]);

  return (
    <div className="aspect-video w-full rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-900 relative">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 dark:bg-gray-900">
          <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
        </div>
      )}
      {error && (
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-100 dark:bg-gray-900 p-4">
          <AlertCircle className="h-8 w-8 text-red-500 mb-2" />
          <p className="text-red-500 text-center">{error}</p>
        </div>
      )}
      <div ref={containerRef} className="w-full h-full" />
    </div>
  );
};