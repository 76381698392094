import React, { useState, useEffect } from 'react';
import { MapPin, Search, Phone, Globe, Clock, Star } from 'lucide-react';
import { useStore } from '../store/useStore';

interface ServiceLocation {
  name: string;
  address: string;
  phone?: string;
  rating?: number;
  openNow?: boolean;
  website?: string;
  distance?: string;
  type: string;
}

export const ServicesDirectory = () => {
  const { selectedLocation } = useStore();
  const [services, setServices] = useState<ServiceLocation[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState('agricultural_store');
  const [error, setError] = useState<string | null>(null);

  const serviceTypes = [
    { id: 'agricultural_store', label: 'Agricultural Stores' },
    { id: 'farm_equipment', label: 'Farm Equipment' },
    { id: 'veterinary_care', label: 'Veterinary Services' },
    { id: 'seed_supplier', label: 'Seed Suppliers' }
  ];

  // Simulated service data for demo purposes
  const getDemoServices = (type: string): ServiceLocation[] => {
    const baseServices: ServiceLocation[] = [
      {
        name: 'Farm Supply Co.',
        address: '123 Agriculture Lane',
        phone: '+1-555-0123',
        rating: 4.5,
        openNow: true,
        website: 'https://example.com',
        distance: '2.3 km',
        type: 'agricultural_store'
      },
      {
        name: 'Tractor & Equipment',
        address: '456 Rural Road',
        phone: '+1-555-0124',
        rating: 4.2,
        openNow: true,
        distance: '3.1 km',
        type: 'farm_equipment'
      },
      {
        name: 'Seeds & More',
        address: '789 Farming Avenue',
        phone: '+1-555-0125',
        rating: 4.7,
        openNow: false,
        website: 'https://example.com',
        distance: '1.8 km',
        type: 'seed_supplier'
      },
      {
        name: 'Rural Vet Clinic',
        address: '321 Animal Care Drive',
        phone: '+1-555-0126',
        rating: 4.8,
        openNow: true,
        website: 'https://example.com',
        distance: '4.2 km',
        type: 'veterinary_care'
      }
    ];

    return baseServices.filter(service => service.type === type);
  };

  useEffect(() => {
    const fetchServices = async () => {
      if (!selectedLocation) return;

      setLoading(true);
      setError(null);

      try {
        // For now, using demo data instead of actual API calls
        const results = getDemoServices(searchType);
        setServices(results);
      } catch (err) {
        console.error('Error fetching services:', err);
        setError('Failed to load services. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, [selectedLocation, searchType]);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white flex items-center">
        <MapPin className="h-6 w-6 mr-2 text-blue-600" />
        Agricultural Services Directory
      </h2>

      <div className="mb-6">
        <div className="flex space-x-4 mb-4">
          {serviceTypes.map(type => (
            <button
              key={type.id}
              onClick={() => setSearchType(type.id)}
              className={`px-4 py-2 rounded-lg transition-colors ${
                searchType === type.id
                  ? 'bg-blue-100 dark:bg-blue-900/20 text-blue-700 dark:text-blue-300'
                  : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-600'
              }`}
            >
              {type.label}
            </button>
          ))}
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-48">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      ) : error ? (
        <div className="bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 p-4 rounded-lg">
          {error}
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {services.map((service, index) => (
            <div
              key={index}
              className="border dark:border-gray-700 rounded-lg p-4 hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors"
            >
              <div className="flex justify-between items-start mb-2">
                <h3 className="font-medium text-gray-900 dark:text-white">
                  {service.name}
                </h3>
                {service.rating && (
                  <div className="flex items-center text-yellow-500">
                    <Star className="h-4 w-4 fill-current" />
                    <span className="ml-1 text-sm">{service.rating}</span>
                  </div>
                )}
              </div>
              
              <div className="space-y-2 text-sm text-gray-600 dark:text-gray-400">
                <p className="flex items-center">
                  <MapPin className="h-4 w-4 mr-2" />
                  {service.address}
                  {service.distance && (
                    <span className="ml-2 text-xs text-gray-500">
                      ({service.distance})
                    </span>
                  )}
                </p>
                
                {service.phone && (
                  <p className="flex items-center">
                    <Phone className="h-4 w-4 mr-2" />
                    {service.phone}
                  </p>
                )}
                
                {service.website && (
                  <p className="flex items-center">
                    <Globe className="h-4 w-4 mr-2" />
                    <a
                      href={service.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 dark:text-blue-400 hover:underline"
                    >
                      Visit Website
                    </a>
                  </p>
                )}
                
                <p className="flex items-center">
                  <Clock className="h-4 w-4 mr-2" />
                  <span className={service.openNow ? 'text-green-600' : 'text-red-600'}>
                    {service.openNow ? 'Open Now' : 'Closed'}
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};